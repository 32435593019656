<template>
  <div id="layout-campaign">
    <b-navbar toggleable="lg" type="dark" fixed="top" class="bg-nav-campaign">
      <b-navbar-brand to="/">
        <img :src="logo" alt="logo" height="51">
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item to="/feelmongolia" class=""><span>Campaign Home</span></b-nav-item>
          <b-nav-item to="/feelmongolia/partners"><span>Partners</span></b-nav-item>
          <b-nav-item to="/feelmongolia/prizes"><span>Prizes</span></b-nav-item>
          <b-nav-item to="/feelmongolia/terms-conditions"><span>Terms & Conditions</span></b-nav-item>
          <b-nav-item to="/feelmongolia/contact"><span>Contact</span> &nbsp;&nbsp;| </b-nav-item>
          <b-nav-item href="#"><i class="fas fa-search"></i></b-nav-item>
          <b-nav-item href="#"><i class="fas fa-share-alt"></i></b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div id="social">
      <svg class="social" data-name="Group 88" xmlns="http://www.w3.org/2000/svg" width="51.194" height="51.194" viewBox="0 0 51.194 51.194">
        <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="25.597" cy="25.597" rx="25.597" ry="25.597" transform="translate(0)" fill="#e33e26"/>
        <g id="Group_87" data-name="Group 87" transform="translate(13.406 13.406)">
          <path id="Path_5117" data-name="Path 5117" d="M17.785,0H6A6.009,6.009,0,0,0,0,6V17.785a6.009,6.009,0,0,0,6,6H17.785a6.009,6.009,0,0,0,6-6V6A6.009,6.009,0,0,0,17.785,0ZM21.2,17.785A3.417,3.417,0,0,1,17.785,21.2H6a3.417,3.417,0,0,1-3.414-3.414V6A3.417,3.417,0,0,1,6,2.588H17.785A3.42,3.42,0,0,1,21.2,6Z" fill="#fff"/>
          <path id="Path_5118" data-name="Path 5118" d="M32.176,7.51A1.726,1.726,0,1,0,33.9,9.236,1.728,1.728,0,0,0,32.176,7.51Z" transform="translate(-14.129 -3.485)" fill="#fff"/>
          <path id="Path_5119" data-name="Path 5119" d="M16.606,10.47A6.126,6.126,0,1,0,22.732,16.6,6.134,6.134,0,0,0,16.606,10.47Zm0,9.668A3.537,3.537,0,1,1,20.148,16.6,3.541,3.541,0,0,1,16.606,20.138Z" transform="translate(-4.712 -4.708)" fill="#fff"/>
        </g>
      </svg>
      <br>
      <br>
      <svg class="social" xmlns="http://www.w3.org/2000/svg" width="51.194" height="51.194" viewBox="0 0 51.194 51.194">
        <g id="Group_90" data-name="Group 90" transform="translate(-1837 -188)">
          <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="25.597" cy="25.597" rx="25.597" ry="25.597" transform="translate(1837 188)" fill="#e33e26"/>
          <path id="Path_5123" data-name="Path 5123" d="M691.051,1216.146h-3.387v12.3h-5.083v-12.275h-2.536v-4.264h2.521c0-.752-.019-1.455.005-2.157a14.475,14.475,0,0,1,.152-2.163,4.222,4.222,0,0,1,3.948-3.669,10.7,10.7,0,0,1,1.23-.094c1.167-.012,2.335,0,3.539,0v4.247c-.458,0-.914,0-1.37,0a11.533,11.533,0,0,0-1.234.03c-.817.095-1.113.382-1.154,1.2-.043.849-.009,1.7-.009,2.6H691.5Z" transform="translate(1176.954 -1001.82)" fill="#fff"/>
        </g>
      </svg>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import logo from '@/assets/img/logo-mongolia-home.png';
export default {
  name: 'layout-campaign',
  data() {
    return {
      logo : logo
    }
  }
}
</script>

<style lang="scss" scoped>
  #layout-campaign {
    #social {
      position: fixed;
      top: 12%;
      right: 40px;
      z-index: 10;
      .social {
        cursor: pointer;
      }
    }

    .bg-nav-campaign {
      background-color: #E33E26;
      .navbar-nav .nav-link {
        color: rgba(255, 255, 255, 1);

        &.router-link-exact-active {
          span {
            border-bottom: 2px #FFF solid;
          }
        }
      }

    }
  }
</style>


